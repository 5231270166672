import { Link } from "react-router-dom";

export default function Footer () {

    return (
        <footer className="text-white box-border sm:p-4 bg-blue-custom">
            <div className="w-full flex gap-6 justify-center sm:justify-around flex-wrap p-4 box-border	">
                <div className="m-2">
                    <span className="font-bold"><Link to="/free-seo-tools-etsy">Free Etsy SEO Tools</Link></span>
                    <div>
                        <div className="my-2 text-sm"><Link to="/sensor" className='text-white visited:text-white'>Sensor</Link></div>
                        <div className="my-2 text-sm"><Link to="/tools/shop-overview" className='text-white visited:text-white'>Etsy Shop Overview</Link></div>
                        <div className="my-2 text-sm"><Link to="/etsy-rank-checker" className='text-white visited:text-white'>Etsy Rank Checker</Link></div>
                        <div className="my-2 text-sm"><Link to="/top-etsy-shops" className='text-white visited:text-white'>Top Etsy Shops</Link></div>
                    </div>
                </div>

                <div className="m-2">
                    <span className="font-bold">Resources</span>
                    <div>
                        <div className="my-2 text-sm"><Link to="/salesdoe-vs-erank-vs-marmalead" className='text-white visited:text-white'>SalesDoe vs eRank vs Marmalead</Link></div>
                        <div className="my-2 text-sm"><Link to="/erank-alternatives" className='text-white visited:text-white'>eRank Alternatives</Link></div>
                        <div className="my-2 text-sm"><Link to="/alura-alternatives" className='text-white visited:text-white'>Alura Alternatives</Link></div>
                        <div className="my-2 text-sm"><Link to="/etsy-hunt-alternatives" className='text-white visited:text-white'>Etsy Hunt Alternatives</Link></div>
                        <div className="my-2 text-sm"><Link to="/marmalead-alternatives" className='text-white visited:text-white'>Marmalead Alternatives</Link></div>
                        <div className="my-2 text-sm"><Link to="/sale-samurai-alternatives" className='text-white visited:text-white'>Sale Samurai Alternatives</Link></div>
                    </div>
                </div>

                <div className="m-2">
                    <span className="font-bold">About</span>
                    <div>
                        <div className="my-2 text-sm"><Link to="/privacy" className='text-white visited:text-white'>Privacy Policy</Link></div>
                        <div className="my-2 text-sm"><Link to="/terms" className='text-white visited:text-white'>Terms</Link></div>
                    </div>
                </div>

                <div className="m-2">
                    <span className="font-bold">Help</span>
                    <div>
                        <div className="my-2 text-sm"><Link to="/help-center" className='text-white visited:text-white'>Help Center</Link></div>
                        <div className="my-2 text-sm"><Link to="/pricing" className='text-white visited:text-white'>Pricing</Link></div>
                    </div>
                </div>
                
                <div className="m-2">
                    <span className="font-bold">Follow Us</span>
                    <div>
                        <div className="my-2 text-sm"><a href="https://www.instagram.com/salesdoe/" target="_blank" className='text-white visited:text-white'>Instagram</a></div>
                        <div className="my-2 text-sm"><a href="https://www.facebook.com/profile.php?id=100089229033359" target="_blank" className='text-white visited:text-white'>Facebook</a></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}